exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-2-js": () => import("./../../../src/pages/form2.js" /* webpackChunkName: "component---src-pages-form-2-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-processing-results-js": () => import("./../../../src/pages/processing-results.js" /* webpackChunkName: "component---src-pages-processing-results-js" */),
  "component---src-pages-sponsor-video-js": () => import("./../../../src/pages/sponsor-video.js" /* webpackChunkName: "component---src-pages-sponsor-video-js" */),
  "component---src-pages-submission-error-js": () => import("./../../../src/pages/submission-error.js" /* webpackChunkName: "component---src-pages-submission-error-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

